export default {
	data() {
		return {
			marketingWorkflowSteps: {
				'entry_point': 'Entry Point: Tags/Forms/Zapier',
				'send_email': 'Send Email',
				'check_email_click': 'Check Email Click',
				'check_email_open': 'Check Email Open',
				'check_form_submission': 'Check Form Submission',
				'follow_up_task': 'Follow-up with leads',
				'wait': 'Time Delay',
				'add_tags': 'Add Tags',
				'remove_tags': 'Remove Tags',
				'add_lead_score': 'Add Lead Score',
				'reduce_lead_score': 'Reduce Lead Score',
				'conditional': 'If/Else Conditional',
				'contact_exit': 'Contact Exited'
			}
		}
	},
    computed: {
        leadTags() {
            return this.$store.state.contacts.tags
        },

        forms() {
            return this.$store.state.contacts.forms
        },

        taskTypes() {
            if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
                return this.$store.state.contacts.allSettings.app.options.taskType
            } else {
                return [
                    { name: 'To-do', id: 'todo' },
                    { name: 'Call', id: 'call' },
                    { name: 'Follow up', id: 'followup' }
                ]
            }
        },

        marketingWorkflowList() {
            return this.$store.state.contacts.marketingWorkflowList
        },
    },
    methods: {
        stepTags(step) {
            if (step.actionData.tags) {
                return step.actionData.tags.map(tag => {
                    return this.leadTags[tag] ? this.leadTags[tag].name : ''
                }).filter(tag => tag).join(', ')
            }
            return ''
        },

        stepForms(step) {
            if (step.actionData.forms) {
                return step.actionData.forms.map(form => {
                    return this.forms[form] ? this.forms[form].name : ''
                }).filter(forms => forms).join(', ')
            }
            return ''
        },

        getLinkedStep(step) {
            let workflow = this.marketingWorkflowList[step.marketing_workflow];
            let linkedStep = null
            if (workflow && step.actionData?.stepId) {
                linkedStep = workflow.steps.find(s => s.stepId === step.actionData.stepId)
            }
            return linkedStep
        },

        stepDescription(step) {
            if (!step) return null;
            if (step.action === 'contact_exit') {
                return 'Contact Exited'
            } else if (step.action === 'entry_point') {
                let description = ''
                if (step.actionData.allContacts) {
                    description += 'CRM: All Contacts'
                } else if (step?.actionData?.tags?.length) {
                    description += ('Tags: ' + this.stepTags(step))
                }
                if (step?.actionData?.forms?.length) {
                    if (description) {
                        description += ' / '
                    }
                    description += ('Forms: ' + this.stepForms(step))
                }
                if (step.actionData?.addZapierLeadToWorkflow) {
                    if (description) {
                        description += ' / '
                    }
                    description += 'Zapier: All New Leads'
                }
                return description
            } else if (step.action === 'add_tags' || step.action === 'remove_tags') {
                if (step?.actionData?.tags?.length) {
                    return ('Tags: ' + this.stepTags(step))
                }
            } else if (step.action === 'wait') {
                if (step.actionData.time && step.actionData.timeUnit) {
                    return `${step.actionData.time} ${step.actionData.timeUnit} delay`
                }
            } else if (step.action === 'add_lead_score') {
                if (step.actionData.score !== null && step.actionData.score > -1) {
                    return `+ ${step.actionData.score.toLocaleString("en-US", { useGrouping: true })}`
                }
            } else if (step.action === 'reduce_lead_score') {
                if (step.actionData.score !== null && step.actionData.score > -1) {
                    return `- ${step.actionData.score.toLocaleString("en-US", { useGrouping: true })}`
                }
            } else if (['check_email_open', 'check_email_click'].includes(step.action)) {
                let linkedStep = this.getLinkedStep(step);
                if (linkedStep?.action === 'send_email' && linkedStep?.actionData?.emailSubject) {
                    return linkedStep.actionData.emailSubject || ''
                }
            } else if (step.action === 'follow_up_task') {
                if (step.actionData.taskType) {
                    const task = this.taskTypes.find(t => t.id === step.actionData.taskType)
                    return (step.actionData.taskTitle || '') + (task ? ' - ' + task.name : '')
                }
            } else if (step.action === 'check_form_submission') {
                const form = this.forms[step.actionData.formId]
                return form ? form.name : ''
            } else if (step.action === 'send_email') {
                return step.actionData.emailSubject || step.name
            }

            return null
        }
    }
}
